<template>
  <div
    class="interface-facture"
    :class="{ 'interface-facture-decalage': computedRouterLinkFacture }"
  >
    <div class="cadre-block-filter">
      <div class="right-block">
        <!-- DATE DEBUT -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('FLFDD')">
          <v-menu
            v-model="isDatePickerDebutVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_debut"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleDateTimePicker(null, 'date_debut')"
                color="#704ad1"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date_debut"
              @input="handleFilter"
              no-title
              locale="fr"
              color="#704ad1"
              header-color="#704ad1"
              event-color="704ad1"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <!-- DATE FIN -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('FLFDF')">
          <v-menu
            v-model="isDatePickerFinVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_fin"
                label="Date fin"
                outlined
                readonly
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleDateTimePicker(null, 'date_fin')"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_fin"
              @input="handleFilter"
              no-title
              locale="fr"
              color="#704ad1"
              header-color="#704ad1"
              event-color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <!--VUE DISPLAY-->
        <div class="select-input-vuetify">
          <v-select
            v-model="vueToDisplay"
            :items="vueOptions"
            dense
            outlined
            hide-details
            class="ma-2"
            color="#704ad1"
            item-color="#704ad1"
            label="Vue à afficher"
            @change="onChangeVue"
            no-data-text="Aucun élément trouvé"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <!-- UPLOAD FILE -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <input-file-excel-num-dossier
                v-show="true"
                :value="nums"
                @searchWithNumDossier="searchWithMultipleFacture"
                :fetchFuntion="fecthFactureNumbersInExcelFile"
                :showCount="true"
                hiddenContent="true"
              />
            </div>
          </template>
          <span>Choisir un fichier</span>
        </v-tooltip>
        <!-- COONTENT LOADING AND MESSAGE ERROR -->
        <!-- <div v-if="isLoading" class="chargement chargement-loading-icon">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->
        <v-progress-circular
          class="ml-2"
          v-if="isLoading"
          indeterminate
          color="#704ad1"
        ></v-progress-circular>
        <!-- <div class="notif-export-file-sci ml-2" v-if="getShowNotifExportFile">
          Préparation du fichier en cours ...
        </div> -->
        <div class="error-message ml-2 mr-2">
          <div v-if="getFactureLibreErroresTh">
            <ul v-if="Array.isArray(getFactureLibreErroresTh)" class="mb-0">
              <li v-for="(e, index) in getFactureLibreErroresTh" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getFactureLibreErroresTh }}</div>
          </div>
        </div>
      </div>
      <div class="left-block">
        <!-- SEARCH -->
        <div class="serach-input mr-2">
          <search
            :value="num"
            @changeSearchValue="changenum"
            hiddenContent="true"
          />
        </div>
        <!-- ADD FACTURE -->
        <router-link
          class="block-icon mr-2 button-height-unset"
          to="/add-facture"
          title="Nouvelle Facture"
          v-if="checkPermission('FLCNF')"
        >
          <font-awesome-icon icon="file-medical" class="file-medical"
        /></router-link>
        <!-- EXPORT EXCEL -->
        <export-dynamic
          :zipRequired="checkPermission('FLTZIP')"
          :xlsRequired="checkPermission('FLXLS')"
          :filter="computedFilter"
          :selected="
            vueToDisplay === true
              ? computedCheckRowsexportXls
              : computedCheckRows
          "
          :exportXls="exportFactureLibre"
          :exportZip="downloadZIP"
          :famille="famille"
          hiddenContent="true"
        />
        <!--SEND MAIL-->
        <sendMail
          v-if="
            ((computedCheckedRows && computedCheckedRows.length > 0) ||
              computedGetDataToUse.length > 0) &&
              checkPermission('FLEES')
          "
          hiddenContent="true"
          :dataToUse="
            vueToDisplay === true ? computedCheckedRows : computedGetDataToUse
          "
          @eventMailSendRefreshTable="refrech()"
        />
        <!-- REGLEMENT FACTURE  -->
        <reglement
          class="mr-1 ml-1"
          v-if="
            checkPermission('FLRG') &&
              computedCheckedRowsOneClient &&
              computedTotal.someApaye > 0
          "
          :dataToUse="computedCheckedRowsOneClientFacture"
          interfaceModal="ReglementFactureRowLibre"
        />
        <!--COMPTABILISE-->
        <!-- <v-tooltip
          bottom
          color="#311B92"
          v-if="
            (computedCheck || factureAvoirChecked.length > 0) &&
              checkPermission('FLC')
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-if="
                (computedCheck || factureAvoirChecked.length > 0) &&
                  checkPermission('FLC')
              "
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click.prevent.stop="FacuresToConfirme()"
              class="block-icon mr-2 button-height-unset"
            >
              <font-awesome-icon icon="check-double" />
            </div>
          </template> 
          <span>Comptabiliser Facture</span>
        </v-tooltip>-->
        <!--VIDER ORDRE-->
        <v-tooltip bottom color="#311B92" v-if="checkPermission('FLVO')">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-if="checkPermission('FLVO')"
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click.prevent.stop="refrech()"
              class="block-icon mr-2 button-height-unset"
            >
              <font-awesome-icon icon="retweet" />
            </div>
          </template>
          <span>Vider ordre</span>
        </v-tooltip>
        <!-- FILTER -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-if="
                checkPermission('FLFV') ||
                  checkPermission('FLFA') ||
                  checkPermission('FLFE') ||
                  checkPermission('FLFT') ||
                  checkPermission('FLFMR') ||
                  checkPermission('FLFC')
              "
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click="openPopover"
              class="block-icon mr-2 button-height-unset"
            >
              <IconFiltre></IconFiltre>
            </div>
          </template>
          <span>Choisir un Filtre</span>
        </v-tooltip>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="350"
          origin="top right"
          content-class="popover-menu-sci"
        >
          <v-card>
            <v-divider class="line-hr-filter"></v-divider>
            <v-card-text class="body-card filter-content-popup">
              <v-form>
                <!-- SOCIETE -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('FLFV')"
                  v-model="isLocalStorageVendeur"
                  :items="computedGetAcheteurs"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Société"
                  color="#704ad1"
                  item-color="#704ad1"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageVendeur.length - 0 }} Société)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageVendeur.length - 0 }} Société)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- CLIENT -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('FLFA')"
                  v-model="isLocalStorageAcheteur"
                  :items="computedGetAcheteurs"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Client"
                  color="#704ad1"
                  item-color="#704ad1"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageAcheteur.length - 0 }} Clients)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageAcheteur.length - 0 }} Clients)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- ETATS -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('FLFE')"
                  v-model="isLocalStorageEtat"
                  :items="etatListFilter"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  color="#704ad1"
                  item-color="#704ad1"
                  class="mb-1"
                  label="Etat"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageEtat.length - 0 }} Etats)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageEtat.length - 0 }} Etats)
                    </span>
                  </template>
                </v-autocomplete>
                <!--TYPES-->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('FLFT')"
                  v-model="isLocalStorageType"
                  :items="typeFacture"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  color="#704ad1"
                  item-color="#704ad1"
                  hide-details
                  class="mb-1"
                  label="Type"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageType.length - 0 }} Types)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageType.length - 0 }} Types)
                    </span>
                  </template>
                </v-autocomplete>
                <!--MODE REGLEMENT-->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('FLFMR')"
                  v-model="isLocalStorageModeReglement"
                  :items="computedTypeReglement"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Mode règlement"
                  color="#704ad1"
                  item-color="#704ad1"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageModeReglement.length - 0 }} Modes)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageModeReglement.length - 0 }} Modes)
                    </span>
                  </template>
                </v-autocomplete>
                <!--CATEGORIES-->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('FLFC')"
                  v-model="isLocalStorageCategories"
                  :items="computeGetcategories"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Catégories"
                  color="#704ad1"
                  item-color="#704ad1"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageCategories.length - 0 }} Catégories)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageCategories.length - 0 }} Catégories)
                    </span>
                  </template>
                </v-autocomplete>
              </v-form>
            </v-card-text>
            <v-card-actions class="popover-action-filter">
              <v-btn
                class="ma-2 capitalize-text"
                outlined
                color="#5E35B1"
                @click="validerPopover"
              >
                Appliquer
              </v-btn>
              <v-btn
                class="ma-2 ml-2 capitalize-text"
                text
                @click="closePopover"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="content-cadre-table">
      <!-- BLOCK SELON FILTER SELECTIONNEE -->
      <div>
        <div class="block-filter-style" v-if="returnTableChips">
          <span class="phrase-style">Les filtres choisie sont: </span>
          <span class="item-filter-style" v-if="date_debut !== null"
            >Date début : {{ date_debut }}</span
          >
          <span class="item-filter-style" v-if="date_fin !== null"
            >Date fin : {{ date_fin }}</span
          >
          <span v-if="num" class="item-filter-style"
            >Recherche : {{ num }}</span
          >
          <span v-if="vueToDisplay !== null" class="item-filter-style"
            >Vue à afficher :
            {{ vueToDisplay == false ? 'Par défaut' : 'Client' }}</span
          >
        </div>
      </div>
      <!-- BLOCK SELON FILTER POPOVER SELECTIONNEE -->
      <v-row class="filter-Block-icon" v-if="returnTableFiltre">
        <!-- SOCIETE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="vendeur.length"
          class="space-table-filter-fac-libre"
        >
          <span class="title-block-chip-filter mr-3">Société : </span>
          <v-sheet elevation="1" class="py-0 px-1 mr-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.text + 'proprietaire'"
                v-for="v in vendeur"
                close
                @click:close="
                  remove(v, 'vendeur', 'handleFilter', 'isLocalStorageVendeur')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- CLIENT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="acheteur.length"
          class="space-table-filter-fac-libre"
        >
          <span class="title-block-chip-filter">Client : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a.text + 'proprietaire'"
                v-for="a in acheteur"
                close
                x-small
                @click:close="
                  remove(
                    a,
                    'acheteur',
                    'handleFilter',
                    'isLocalStorageAcheteur'
                  )
                "
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- STATUT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="etat.length"
          class="space-table-filter-fac-libre"
        >
          <span class="title-block-chip-filter">Etat : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="s.text + 'proprietaire'"
                v-for="s in etat"
                close
                @click:close="
                  remove(s, 'etat', 'handleFilter', 'isLocalStorageEtat')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- TYPES -->
        <v-col
          :cols="computedLengthFilter"
          v-if="type.length"
          class="space-table-filter-fac-libre"
        >
          <span class="title-block-chip-filter">Type : </span>
          <v-sheet elevation="1" class="py-0 px-1 mr-3">
            <v-chip-group mandatory>
              <v-chip
                :key="s.text + 'proprietaire'"
                v-for="s in type"
                close
                @click:close="
                  remove(s, 'type', 'handleFilter', 'isLocalStorageType')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- Mode règlement -->
        <v-col
          :cols="computedLengthFilter"
          v-if="mode_reglement.length"
          class="space-table-filter-fac-libre"
        >
          <span class="title-block-chip-filter">Mode règlement : </span>
          <v-sheet elevation="1" class="py-0 px-1 mr-3">
            <v-chip-group mandatory>
              <v-chip
                :key="s.text + 'proprietaire'"
                v-for="s in mode_reglement"
                close
                @click:close="
                  remove(
                    s,
                    'mode_reglement',
                    'handleFilter',
                    'isLocalStorageModeReglement'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- CATEGORY -->
        <v-col
          :cols="computedLengthFilter"
          v-if="categorie.length"
          class="space-table-filter-fac-libre"
        >
          <span class="title-block-chip-filter">Catégories : </span>
          <v-sheet elevation="1" class="py-0 px-1 mr-3">
            <v-chip-group mandatory>
              <v-chip
                :key="s.text + 'proprietaire'"
                v-for="s in categorie"
                close
                @click:close="
                  remove(
                    s,
                    'categorie',
                    'handleFilter',
                    'isLocalStorageCategories'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- TABLE FACTURE LIBRE VUE PAR DEFAUT -->
      <div
        class="table-fac-libre"
        :class="{
          'padding-table': returnTableFiltre
        }"
      >
        <v-data-table
          v-if="vueToDisplay === false"
          v-model="selectedTable"
          :headers="computedFields"
          :items="getFacturesLibresTh"
          :class="{
            'height-filter-chips-table': returnTableChips && !returnTableFiltre,
            'height-filter-table': returnTableFiltre && !returnTableChips,
            'height-filter-tous': returnTableChips && returnTableFiltre
          }"
          @item-expand="getFacturesLibresTh"
          class="table-facture-libre"
          hide-default-footer
          show-expand
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          :calculate-widths="false"
          :fixed-header="true"
          :items-per-page="perPage"
          hide-action
          :no-data-text="
            isLoading
              ? 'Chargement en cours ...'
              : `Il n'y a aucune donnée à afficher dans ce tableau.`
          "
        >
          <!-- HEADERS TABLE -->
          <template v-slot:[`header.check_all`]="{ header }">
            <td>
              <span v-if="header.value != 'check_all'">
                {{ header.label }}</span
              >
              <div v-else>
                <v-checkbox
                  class="checked-global"
                  v-model="checkAll"
                  @change="checkAllFunction"
                  color="#704ad1"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
          </template>
          <!-- BODY TABLE -->
          <template v-slot:[`item.check_all`]="{ item }">
            <td class="custom-cell sticky-header-1">
              <v-checkbox
                class="checked-groupe"
                v-model="item.check"
                @change="checkOneFunction(item.check)"
                color="#704ad1"
                :true-value="true"
                :false-value="false"
                hide-details
              ></v-checkbox>
            </td>
          </template>
          <!--ROWS DETAILS-->
          <template v-slot:[`item.data-table-expand`]="{ item }">
            <td class="custom-cell sticky-header-2">
              <button
                class="btn btn-anomalie"
                v-if="item && item.FactureAvoir && item.FactureAvoir.length > 0"
                @click="showRowDetailsVueDefault(item)"
              >
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-plus']"
                  color="#704ad1"
                  title="Afficher Facture(s) Avoir(s)"
                  v-if="item.showDetails == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-minus']"
                  color="#704ad1"
                  title="Masquer Facture(s) Avoir(s)"
                  v-if="item.showDetails == true"
                />
              </button>
            </td>
          </template>
          <!--FILES-->
          <template v-slot:[`item.pices-icon`]="{ item }">
            <td class="custom-cell">
              <font-awesome-icon
                v-if="item && item.files && item.files.length"
                icon="paperclip"
              />
            </td>
          </template>
          <!--CLIENT-->
          <template v-slot:[`item.Acheteur`]="{ item }">
            <td class="custom-cell">{{ item.acheteur }}</td>
          </template>
          <!--SOCIETE-->
          <template v-slot:[`item.Vendeur`]="{ item }">
            <td class="custom-cell">{{ item.vendeur }}</td>
          </template>
          <!--TYPE-->
          <template v-slot:[`item.type`]="{ item }">
            <td class="custom-cell">{{ item.type }}</td>
          </template>
          <!--NUM-->
          <template v-slot:[`item.num`]="{ item }">
            <td class="custom-cell">{{ item.num }}</td>
          </template>
          <!--RESTE-->
          <template v-slot:[`item.reste`]="{ item }">
            <td class="custom-cell">{{ item.reste }} €</td>
          </template>
          <!--ETAT-->
          <template v-slot:[`item.etat`]="{ item }">
            <td class="custom-cell">{{ item.etat }}</td>
          </template>
          <!--DATE CREATION-->
          <template v-slot:[`item.date_creation`]="{ item }">
            <td class="custom-cell">
              {{ formateDateGetters(item.date_creation) }}
            </td>
          </template>
          <!--TOTAL NET HT-->
          <template v-slot:[`item.total_net_ht`]="{ item }">
            <td class="custom-cell">{{ item.total_net_ht }} €</td>
          </template>
          <!--TOTAL TVA-->
          <template v-slot:[`item.total_tva`]="{ item }">
            <td class="custom-cell">{{ item.total_tva }} €</td>
          </template>
          <!--TOTAL TTC-->
          <template v-slot:[`item.total_ttc`]="{ item }">
            <td class="custom-cell">{{ item.total_ttc }} €</td>
          </template>
          <!--DATE PAIEMENT-->
          <template v-slot:[`item.date_paiement`]="{ item }">
            <td class="custom-cell">
              {{ item.date_paiement ? item.date_paiement : '-' }}
            </td>
          </template>
          <!--MONTANT REGLE-->
          <template v-slot:[`item.reglement`]="{ item }">
            <td class="custom-cell">
              <div class="flex-div">
                {{ item.montant_regle ? item.montant_regle : 0 }} €
                <PopoverReglementFacture
                  v-show="item && item.reglements && item.reglements.length"
                  :factureReglement="item.reglements"
                  :permissionModifier="checkPermission('FLURG')"
                  dataInterface="libre"
                >
                </PopoverReglementFacture>
              </div>
            </td>
          </template>
          <!--STATUT CLIENT-->
          <template v-slot:[`item.statut_client`]="{ item }">
            <td class="custom-cell">{{ item.statut_client }}</td>
          </template>
          <!--CATEGORIE-->
          <template v-slot:[`item.categorie`]="{ item }">
            <td class="custom-cell">
              {{ item.categorie ? item.categorie : '-' }}
            </td>
          </template>
          <!--CONFIRME-->
          <template v-slot:[`item.confirme`]="{ item }">
            <td class="custom-cell">
              {{ item.confirme === true ? 'OUI' : 'NON' }}
            </td>
          </template>
          <!--CREE PAR-->
          <template v-slot:[`item.User`]="{ item }">
            <td class="custom-cell">{{ item.user }}</td>
          </template>
          <!--NUM PROFORMA-->
          <template v-slot:[`item.num_proforma`]="{ item }">
            <td class="custom-cell">
              {{ item.num_proforma ? item.num_proforma : '-' }}
            </td>
          </template>
          <template v-slot:[`item.action`]="{ item, index }">
            <td class="custom-cell  small-btn-dropdown-libre sticky-header-end">
              <v-menu
                v-model="item.checked"
                :close-on-content-click="false"
                :nudge-width="100"
                :left="true"
                offset-x
                class="actions-menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="#704ad1" dark v-bind="attrs" v-on="on">
                    <font-awesome-icon icon="bars" />
                  </v-btn>
                </template>
                <v-card>
                  <v-list class="actions-list">
                    <!--VISUALISATION FACTURE-->
                    <v-list-item
                      v-if="checkPermission('FLAF')"
                      class="list-dropdown"
                    >
                      <v-list-item-action
                        v-if="checkPermission('FLAF')"
                        title="Afficher document "
                      >
                        <VisualisationFacture
                          :awesome="false"
                          :facture="item.id"
                          :permission="checkPermission('FLAF')"
                          title="Afficher document"
                          :detailsBlock="true"
                          :indexBegin="indexBegin"
                          :indexEnd="indexEnd"
                          @eventOpenModalVisual="
                            visualisation(index, item, 'default')
                          "
                          @visualisationPrecedent="
                            visualisationStep('previous', 'default')
                          "
                          @visualisationAvant="
                            visualisationStep('next', 'default')
                          "
                          @FacuresToDownloadOnePDF="
                            FacuresToDownloadOnePDFEvent('default')
                          "
                          @onClickNavigate="
                            onClickNavigate(
                              getFacturesLibresTh[indexBegin].id,
                              'Duplication'
                            )
                          "
                          :isAvoir="isAvoir"
                          ref="VisualisationFacture"
                        ></VisualisationFacture>
                      </v-list-item-action>
                    </v-list-item>
                    <!--MODIFICATION FACTURE-->
                    <v-list-item
                      class="list-dropdown"
                      v-if="
                        item &&
                          (item.user_id === idConnecter ||
                            computeVendeursNames.includes(item.vendeur)) &&
                          ((checkPermission('FLMF') &&
                            item.confirme === false &&
                            item.type != 'Facture d\'avoir' &&
                            (item.etat === 'Créé' || item.etat === 'A payer') &&
                            item &&
                            item.FactureAvoir &&
                            item.FactureAvoir.length === 0) ||
                            (item.type == 'Facture d\'avoir' &&
                              item.motif_avoir == 'ristourne' &&
                              checkPermission('FLMFFC')))
                      "
                    >
                      <v-list-item-action
                        @click.prevent.stop="
                          onClickNavigate(item.id, 'Modification')
                        "
                      >
                        <div class="action-title">
                          Modifier document
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <!--TELECHARGER FACTURE-->
                    <v-list-item
                      v-if="checkPermission('FLTFP')"
                      class="list-dropdown"
                    >
                      <v-list-item-action
                        @click.prevent.stop="FacuresToDownloadOnePDF(item)"
                      >
                        <div class="action-title">
                          Télécharger document
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <!--DUPLIQUER FACTURE-->
                    <v-list-item
                      class="list-dropdown"
                      v-if="
                        item &&
                          item.type !== 'Facture d\'avoir' &&
                          checkPermission('FLDF') &&
                          item.FactureAvoir &&
                          !item.FactureAvoir.some(
                            e => e.motif_avoir === 'avoir_global'
                          )
                      "
                    >
                      <v-list-item-action
                        @click.prevent.stop="
                          onClickNavigate(item.id, 'Duplication')
                        "
                      >
                        <div class="action-title">
                          Dupliquer document
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <!--AVOIR SUR FACTURE-->
                    <v-list-item
                      class="list-dropdown"
                      v-if="
                        ((checkPermission('FLAVF') &&
                          item &&
                          (item.user_id === idConnecter ||
                            roleConnecter === 'admin')) ||
                          checkPermission('FLCAPT')) &&
                          item &&
                          item.FactureAvoir &&
                          item.FactureAvoir.length === 0 &&
                          item.type !== 'Facture d\'avoir' &&
                          item.type !== 'Facture proforma' &&
                          item.type !== 'Devis'
                      "
                    >
                      <v-list-item-action
                        @click.prevent.stop="openConfirmAvoirModal(item)"
                      >
                        <div class="action-title">
                          Avoir sur Facture
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <!--TRANSFORMER VERS FACTURE-->
                    <v-list-item
                      v-if="
                        item.type === 'Facture proforma' ||
                          item.type === 'Devis'
                      "
                      class="list-dropdown"
                    >
                      <v-list-item-action
                        @click.prevent.stop="trasformFacture(item)"
                      >
                        <div class="action-title">
                          Transformer vers facture
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <!--PIECE JOINTES-->
                    <v-list-item
                      class="list-dropdown"
                      v-if="
                        checkPermission('FLPJVPD') &&
                          item.type !== 'Facture d\'avoir'
                      "
                    >
                      <v-list-item-action
                        @click.prevent.stop="handleFichiers(item, 'show')"
                      >
                        <div class="action-title">
                          Pièce jointes
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <!--HISTORIQUE-->
                    <v-list-item
                      class="list-dropdown"
                      v-if="checkPermission('FLHFAVPD')"
                    >
                      <v-list-item-action>
                        <Historique
                          :awesome="false"
                          :permission="checkPermission('FLHFAVPD')"
                          :index="item.id"
                          :dataToUse="item.historique"
                          @returnHistorique="returnHistorique"
                          :styleMenuBtn="true"
                          title="Historique document"
                        />
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </td>
          </template>
          <!-- EXPANDED TABLE -->
          <template #expanded-item="{ headers, item }">
            <td
              class="td-expanded"
              :colspan="headers.length"
              v-if="
                item &&
                  item.FactureAvoir &&
                  item.FactureAvoir.length > 0 &&
                  item.showDetails == true
              "
            >
              <table :items="item.FactureAvoir" class="table-expanded">
                <tbody>
                  <tr v-for="data in item.FactureAvoir" :key="data.id">
                    <td
                      class="td-expanded sticky-header-1"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <v-checkbox
                        class="checked-expanded"
                        v-model="item.checkAvoir"
                        @change="checkOneAvoirFunction(data)"
                        color="#704ad1"
                        :true-value="true"
                        :false-value="false"
                        hide-details
                      ></v-checkbox>
                    </td>
                    <!-- VIDE -->
                    <td
                      class="td-expanded sticky-header-2"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <span></span>
                    </td>
                    <!-- VIDE -->
                    <td
                      class="td-expanded sticky-header-2"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <span> </span>
                    </td>
                    <!-- CLIENT -->
                    <td class="td-expanded" v-if="checkPermission('FLACC')">
                      {{ data.acheteur }}
                    </td>
                    <!-- SOCIETE -->
                    <td v-if="checkPermission('FLACS')" class="td-expanded">
                      {{ data.vendeur }}
                    </td>
                    <!-- TYPE -->
                    <td class="td-expanded" v-if="checkPermission('FLACTD')">
                      {{ data.type }}
                    </td>
                    <!-- NUM -->
                    <td class="td-expanded" v-if="checkPermission('FLACN')">
                      <div class="flex-div">
                        {{ data.num }}
                      </div>
                    </td>
                    <!-- DATE CREATION -->
                    <td class="td-expanded" v-if="checkPermission('FLACDC')">
                      {{ formateDateGetters(data.date_creation) }}
                    </td>
                    <!-- MONTANT REGLE -->
                    <td class="td-expanded" v-if="checkPermission('FLACRAP')">
                      0 €
                    </td>
                    <!-- ETAT -->
                    <td v-if="checkPermission('FLACE')" class="td-expanded">
                      {{ data.etat == 'Annulée' ? 'Verrouillée' : '-' }}
                    </td>
                    <!-- NET HT -->
                    <td class="td-expanded" v-if="checkPermission('FLACNHT')">
                      {{ data.total_net_ht }} €
                    </td>
                    <!-- TVA -->
                    <td class="td-expanded" v-if="checkPermission('FLACTVA')">
                      {{ data.total_tva }} €
                    </td>
                    <!-- TTC -->
                    <td class="td-expanded" v-if="checkPermission('FLACTTTC')">
                      {{ data.total_ttc }} €
                    </td>
                    <!-- DATE PAIEMENT -->
                    <td class="td-expanded" v-if="checkPermission('FLADP')">
                      {{ item.date_paiement ? item.date_paiement : '-' }}
                    </td>
                    <!-- REGLEMENT -->
                    <td class="td-expanded" v-if="checkPermission('FLARG')">
                      -
                    </td>
                    <!-- STATUT CLIENT -->
                    <td class="td-expanded" v-if="checkPermission('FLACSC')">
                      {{ data.statut_client }}
                    </td>
                    <!-- CATEGORIE -->
                    <td class="td-expanded" v-if="checkPermission('FLACCAT')">
                      {{
                        data.categorie && data.categorie.nom_categorie
                          ? data.categorie.nom_categorie
                          : '-'
                      }}
                    </td>
                    <!-- CONFIRME -->
                    <td class="td-expanded" v-if="checkPermission('FLACCONF')">
                      {{ data.confirme === true ? 'OUI' : 'NON' }}
                    </td>
                    <!-- CREE PAR -->
                    <td class="td-expanded" v-if="checkPermission('FLACCP')">
                      {{ data.user ? data.user : '-' }}
                    </td>
                    <!-- NUM PROFORMA VIDE  -->
                    <td class="td-expanded" v-if="checkPermission('FLACRP')">
                      -
                    </td>
                    <!--ACTIONS-->
                    <td
                      class="td-expanded small-btn-dropdown-libre sticky-header-end"
                      v-if="checkPermission('FLAA')"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <v-menu
                        v-model="data.checked"
                        :close-on-content-click="false"
                        :nudge-width="100"
                        :left="true"
                        offset-x
                        class="actions-menu"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="#704ad1"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            small
                            icon
                            text
                          >
                            <font-awesome-icon
                              icon="bars"
                              class="icon-color-cdg"
                            />
                          </v-btn>
                        </template>
                        <v-card>
                          <v-list class="actions-list">
                            <!--VISUALISATION FACTURE-->
                            <v-list-item
                              v-if="checkPermission('FLAF')"
                              class="list-dropdown"
                            >
                              <v-list-item-action
                                v-if="checkPermission('FLAF')"
                                title="Afficher document"
                              >
                                <VisualisationFacture
                                  :awesome="false"
                                  :facture="data.id"
                                  :permission="checkPermission('FLAF')"
                                  title="Afficher document"
                                ></VisualisationFacture>
                              </v-list-item-action>
                            </v-list-item>
                            <!--MODIFICATION FACTURE-->
                            <v-list-item
                              class="list-dropdown"
                              v-if="
                                (data.user_id === idConnecter ||
                                  computeVendeursNames.includes(
                                    data.vendeur
                                  )) &&
                                  ((checkPermission('FLMF') &&
                                    data.confirme === false &&
                                    data.type != 'Facture d\'avoir' &&
                                    (data.etat === 'Créé' ||
                                      data.etat === 'A payer') &&
                                    data &&
                                    data.FactureAvoir &&
                                    data.FactureAvoir.length === 0) ||
                                    (data.type == 'Facture d\'avoir' &&
                                      data.motif_avoir == 'ristourne' &&
                                      checkPermission('FLMFFC')))
                              "
                            >
                              <v-list-item-action
                                @click.prevent.stop="
                                  onClickNavigate(item.id, 'Modification')
                                "
                              >
                                <div class="action-title">
                                  Modifier document
                                </div>
                              </v-list-item-action>
                            </v-list-item>
                            <!--TELECHARGER FACTURE-->
                            <v-list-item
                              v-if="checkPermission('FLTFP')"
                              class="list-dropdown"
                            >
                              <v-list-item-action
                                @click.prevent.stop="
                                  FacuresToDownloadOnePDF(data)
                                "
                              >
                                <div class="action-title">
                                  Télécharger document
                                </div>
                              </v-list-item-action>
                            </v-list-item>
                            <!--HISTORIQUE FACTURE-->
                            <v-list-item
                              class="list-dropdown"
                              v-if="checkPermission('FLHFAVPD')"
                            >
                              <v-list-item-action>
                                <Historique
                                  :awesome="false"
                                  :permission="checkPermission('FLHFAVPD')"
                                  :index="data.id"
                                  :dataToUse="data.historique"
                                  @returnHistorique="returnHistorique"
                                  :styleMenuBtn="true"
                                  title="Historique document"
                                />
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </template>
        </v-data-table>
      </div>
      <!-- TABLE FACTURE LIBRE VUE CLIENT -->
      <v-data-table
        v-if="vueToDisplay === true"
        v-model="selectedTable"
        :headers="computedFields"
        :items="getFacturesLibresTh"
        :class="{
          'height-filter-chips-table': returnTableChips && !returnTableFiltre,
          'height-filter-table': returnTableFiltre && !returnTableChips,
          'height-filter-tous': returnTableChips && returnTableFiltre
        }"
        class="table-facture-client"
        hide-default-footer
        show-expand
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :calculate-widths="false"
        :fixed-header="true"
        :items-per-page="perPage"
        hide-action
        :no-data-text="
          isLoading
            ? 'Chargement en cours ...'
            : `Il n'y a aucun enregistrement à afficher`
        "
      >
        <!-- HEADERS TABLE -->
        <template v-slot:[`header.check_all`]="{ header }">
          <td>
            <span v-if="header.value != 'check_all'"> {{ header.label }}</span>
            <div v-else>
              <v-checkbox
                class="checked-global"
                v-model="checkAll"
                @change="checkAllFunctionClienVue"
                color="#704ad1"
                :true-value="true"
                :false-value="false"
                hide-details
              ></v-checkbox>
            </div>
          </td>
        </template>
        <!-- BODY TABLE -->
        <template v-slot:[`item.check_all`]="{ item }">
          <td class="custom-cell sticky-header-1">
            <v-checkbox
              class="checked-groupe"
              v-model="item.checkClient"
              @change="checkAllVerif(item)"
              color="#704ad1"
              :true-value="true"
              :false-value="false"
              hide-details
            ></v-checkbox>
          </td>
        </template>
        <template v-slot:[`item.data-table-expand`]="{ item }">
          <td class="custom-cell sticky-header-2">
            <button class="btn btn-anomalie" @click="getDataForOneRow(item)">
              <font-awesome-icon
                class="icon-plus"
                :icon="['fas', 'square-plus']"
                color="#704ad1"
                title="Afficher Facture(s)"
                v-if="item.showingDetails == false"
              />
              <font-awesome-icon
                class="icon-plus"
                :icon="['fas', 'square-minus']"
                color="#704ad1"
                title="Masquer Facture(s)"
                v-if="item.showingDetails == true"
              />
            </button>
          </td>
        </template>
        <template v-slot:[`item.pices-icon`]="{}">
          <td class="custom-cell">
            -
          </td>
        </template>
        <template v-slot:[`item.Acheteur`]="{ item }">
          <td class="custom-cell">{{ item.name }} ({{ item.count }})</td>
        </template>
        <template v-slot:[`item.Vendeur`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.type`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.num`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.reste`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.etat`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.date_creation`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.total_net_ht`]="{ item }">
          <td class="custom-cell">{{ item.total_ht }} €</td>
        </template>
        <template v-slot:[`item.total_tva`]="{ item }">
          <td class="custom-cell">{{ item.total_tva }} €</td>
        </template>
        <template v-slot:[`item.total_ttc`]="{ item }">
          <td class="custom-cell">{{ item.total_ttc }} €</td>
        </template>
        <template v-slot:[`item.date_paiement`]="{}">
          <td class="custom-cell">
            -
          </td>
        </template>
        <template v-slot:[`item.reglement`]="{}">
          <td class="custom-cell">
            -
          </td>
        </template>
        <template v-slot:[`item.statut_client`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.categorie`]="{}">
          <td class="custom-cell">
            -
          </td>
        </template>
        <template v-slot:[`item.confirme`]="{}">
          <td class="custom-cell">
            -
          </td>
        </template>
        <template v-slot:[`item.User`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.num_proforma`]="{}">
          <td class="custom-cell">
            -
          </td>
        </template>
        <!-- EXPANDED TABLE -->
        <template #expanded-item="{ headers, item }">
          <td
            class="td-expanded"
            :colspan="headers.length"
            v-if="
              item &&
                item.factures &&
                item.factures.length > 0 &&
                item.showingDetails == true
            "
          >
            <table :items="item.factures" class="table-expanded">
              <tbody v-for="(data, indexF) in item.factures" :key="data.id">
                <tr>
                  <td
                    class="td-expanded sticky-header-1"
                    style="width:40px  !important ; min-width:40px !important"
                  >
                    <v-checkbox
                      class="checked-expanded"
                      v-model="data.check"
                      @change="checkOneAvoirFunction(data)"
                      color="#704ad1"
                      :true-value="true"
                      :false-value="false"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <!-- VIDE -->
                  <td
                    class="td-expanded sticky-header-2"
                    style="width:40px  !important ; min-width:40px !important"
                  >
                    <button
                      v-if="data.FactureAvoir.length > 0"
                      class="btn btn-anomalie"
                      @click="data.showDetails = !data.showDetails"
                    >
                      <font-awesome-icon
                        class="icon-plus-double"
                        icon="angle-right"
                        color="#704ad1"
                        title="Afficher Facture(s) Avoir(s)"
                        v-if="data.showDetails == false"
                      />
                      <font-awesome-icon
                        class="icon-plus-double"
                        icon="angle-down"
                        color="#704ad1"
                        title="Masquer Facture(s) Avoir(s)"
                        v-if="data.showDetails == true"
                      />
                    </button>
                  </td>
                  <td
                    class="td-expanded sticky-header-2"
                    style="width:40px  !important ; min-width:40px !important"
                  >
                    <font-awesome-icon
                      v-if="data && data.files && data.files.length"
                      icon="paperclip"
                    />
                  </td>
                  <!-- CLIENT   -->
                  <td class="td-expanded" v-if="checkPermission('FLACC')">
                    {{ data.acheteur }}
                  </td>
                  <!--  SOCIETE -->
                  <td v-if="checkPermission('FLACS')" class="td-expanded">
                    {{ data.vendeur }}
                  </td>
                  <!-- TYPE -->
                  <td class="td-expanded" v-if="checkPermission('FLACTD')">
                    {{ data.type }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FLACN')">
                    <div class="flex-div">
                      {{ data.num }}
                    </div>
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FLACDC')">
                    {{ formateDateGetters(data.date_creation) }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FLACRAP')">
                    {{ data.reste ? data.reste : 0 }} €
                  </td>
                  <td v-if="checkPermission('FLACE')" class="td-expanded">
                    {{ data.etat }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FLACNHT')">
                    {{ data.total_net_ht }} €
                  </td>

                  <td class="td-expanded" v-if="checkPermission('FLACTVA')">
                    {{ data.total_tva }} €
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FLACTTTC')">
                    {{ data.total_ttc }} €
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FLADP')">
                    {{ item.date_paiement ? item.date_paiement : '-' }}
                  </td>

                  <td class="td-expanded" v-if="checkPermission('FLARG')">
                    -
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FLACSC')">
                    {{ data.statut_client ? data.statut_client : '-' }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FLACCAT')">
                    {{
                      data.categorie && data.categorie.nom_categorie
                        ? data.categorie.nom_categorie
                        : '-'
                    }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FLACCONF')">
                    {{ data.confirme === true ? 'OUI' : 'NON' }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FLACCP')">
                    {{ data.user ? data.user : '-' }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FLACRP')">
                    -
                  </td>
                  <td
                    class="custom-cell  small-btn-dropdown-libre sticky-header-end"
                    v-if="checkPermission('FLAA')"
                    style="width:40px  !important ; min-width:40px !important"
                  >
                    <v-menu
                      v-model="data.checked"
                      :close-on-content-click="false"
                      :nudge-width="100"
                      :left="true"
                      offset-x
                      class="actions-menu"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#704ad1" dark v-bind="attrs" v-on="on">
                          <font-awesome-icon icon="bars" />
                        </v-btn>
                      </template>
                      <v-card>
                        <v-list class="actions-list">
                          <!--VISUALISATION FACTURE-->
                          <v-list-item
                            v-if="checkPermission('FLAF')"
                            class="list-dropdown"
                          >
                            <v-list-item-action
                              v-if="checkPermission('FLAF')"
                              title="Afficher document"
                            >
                              <VisualisationFacture
                                :awesome="false"
                                :facture="data.id"
                                :permission="checkPermission('FLAF')"
                                title="Afficher document"
                                :detailsBlock="true"
                                :indexBegin="indexBegin"
                                :indexEnd="indexEnd"
                                @eventOpenModalVisual="
                                  visualisation(
                                    indexF,
                                    data,
                                    'client',
                                    item.factures.length
                                  )
                                "
                                @visualisationPrecedent="
                                  visualisationStep(
                                    'previous',
                                    'client',
                                    item,
                                    data.id
                                  )
                                "
                                @visualisationAvant="
                                  visualisationStep(
                                    'next',
                                    'client',
                                    item,
                                    data.id
                                  )
                                "
                                @FacuresToDownloadOnePDF="
                                  FacuresToDownloadOnePDFEvent('client', item)
                                "
                                @onClickNavigate="
                                  onClickNavigate(
                                    item.factures[indexBegin].id,
                                    'Duplication'
                                  )
                                "
                                :isAvoir="isAvoir"
                                ref="VisualisationFacture"
                              ></VisualisationFacture>
                            </v-list-item-action>
                          </v-list-item>
                          <!--MODIFICATION FACTURE-->
                          <v-list-item
                            class="list-dropdown"
                            v-if="
                              data &&
                                (data.user_id === idConnecter ||
                                  computeVendeursNames.includes(
                                    data.vendeur
                                  )) &&
                                ((checkPermission('FLMF') &&
                                  data.confirme === false &&
                                  data.type != 'Facture d\'avoir' &&
                                  (data.etat === 'Créé' ||
                                    data.etat === 'A payer') &&
                                  data &&
                                  data.FactureAvoir &&
                                  data.FactureAvoir.length === 0) ||
                                  (data.type == 'Facture d\'avoir' &&
                                    data.motif_avoir == 'ristourne' &&
                                    checkPermission('FLMFFC')))
                            "
                          >
                            <v-list-item-action
                              @click.prevent.stop="
                                onClickNavigate(data.id, 'Modification')
                              "
                            >
                              <div class="action-title">
                                Modifier document
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item
                            v-if="checkPermission('FLTFP')"
                            class="list-dropdown"
                          >
                            <v-list-item-action
                              @click.prevent.stop="
                                FacuresToDownloadOnePDF(data)
                              "
                            >
                              <div class="action-title">
                                Télécharger document
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item
                            class="list-dropdown"
                            v-if="
                              data &&
                                data.type !== 'Facture d\'avoir' &&
                                checkPermission('FLDF') &&
                                data.FactureAvoir &&
                                !data.FactureAvoir.some(
                                  e => e.motif_avoir === 'avoir_global'
                                )
                            "
                          >
                            <v-list-item-action
                              @click.prevent.stop="
                                onClickNavigate(data.id, 'Duplication')
                              "
                            >
                              <div class="action-title">
                                Dupliquer document
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item
                            class="list-dropdown"
                            v-if="
                              ((checkPermission('FLAVF') &&
                                data &&
                                (data.user_id === idConnecter ||
                                  roleConnecter === 'admin')) ||
                                checkPermission('FLCAPT')) &&
                                data &&
                                data.FactureAvoir &&
                                data.FactureAvoir.length === 0 &&
                                data.type !== 'Facture d\'avoir' &&
                                data.type !== 'Facture proforma' &&
                                data.type !== 'Devis'
                            "
                          >
                            <v-list-item-action
                              @click.prevent.stop="openConfirmAvoirModal(data)"
                            >
                              <div class="action-title">
                                Avoir sur Facture
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item
                            class="list-dropdown"
                            v-if="
                              item.type === 'Facture proforma' ||
                                item.type === 'Devis'
                            "
                          >
                            <v-list-item-action
                              @click.prevent.stop="trasformFacture(data)"
                            >
                              <div class="action-title">
                                Transformer vers facture
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item
                            class="list-dropdown"
                            v-if="
                              checkPermission('FLPJVPD') &&
                                data.type !== 'Facture d\'avoir'
                            "
                          >
                            <v-list-item-action
                              @click.prevent.stop="handleFichiers(data, 'show')"
                            >
                              <div class="action-title">
                                Pièce jointes
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item
                            class="list-dropdown"
                            v-if="checkPermission('FLHFAVPD')"
                          >
                            <v-list-item-action>
                              <Historique
                                :awesome="false"
                                :permission="checkPermission('FLHFAVPD')"
                                :index="data.id"
                                :dataToUse="data.historique"
                                @returnHistorique="returnHistorique"
                                :styleMenuBtn="true"
                                title="Historique document"
                              />
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </td>
                </tr>
                <template v-if="data.showDetails == true">
                  <tr v-for="avoir in data.FactureAvoir" :key="avoir.id">
                    <td
                      class="td-expanded sticky-header-1"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <v-checkbox
                        class="checked-expanded"
                        v-model="avoir.checkAvoir"
                        @change="checkOneAvoirFunction(data)"
                        color="#704ad1"
                        :true-value="true"
                        :false-value="false"
                        hide-details
                      ></v-checkbox>
                    </td>
                    <!-- VIDE -->
                    <td
                      class="td-expanded sticky-header-2"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <span> </span>
                    </td>
                    <td
                      class="td-expanded sticky-header-2"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <span> </span>
                    </td>
                    <!-- CLIENT -->
                    <td class="td-expanded" v-if="checkPermission('FLACC')">
                      {{ avoir.acheteur }}
                    </td>
                    <!-- SOCIETE -->
                    <td v-if="checkPermission('FLACS')" class="td-expanded">
                      {{ avoir.vendeur }}
                    </td>
                    <!-- MOIS -->
                    <td class="td-expanded" v-if="checkPermission('FLACTD')">
                      {{ avoir.type }}
                    </td>
                    <td class="td-expanded" v-if="checkPermission('FLACN')">
                      <div class="flex-div">
                        {{ avoir.num }}
                      </div>
                    </td>
                    <td class="td-expanded" v-if="checkPermission('FLACDC')">
                      {{ formateDateGetters(avoir.date_creation) }}
                    </td>
                    <td class="td-expanded" v-if="checkPermission('FLACRAP')">
                      0 €
                    </td>
                    <td v-if="checkPermission('FLACE')" class="td-expanded">
                      {{ avoir.etat }}
                    </td>
                    <td class="td-expanded" v-if="checkPermission('FLACNHT')">
                      {{ avoir.total_net_ht }} €
                    </td>

                    <td class="td-expanded" v-if="checkPermission('FLACTVA')">
                      {{ avoir.total_tva }} €
                    </td>
                    <td class="td-expanded" v-if="checkPermission('FLACTTTC')">
                      {{ avoir.total_ttc }} €
                    </td>
                    <td class="td-expanded" v-if="checkPermission('FLADP')">
                      {{ avoir.date_paiement ? item.date_paiement : '-' }}
                    </td>

                    <td class="td-expanded" v-if="checkPermission('FLARG')">
                      -
                    </td>
                    <td class="td-expanded" v-if="checkPermission('FLACSC')">
                      {{ avoir.statut_client ? avoir.statut_client : '-' }}
                    </td>
                    <td class="td-expanded" v-if="checkPermission('FLACCAT')">
                      {{
                        avoir.categorie && avoir.categorie.nom_categorie
                          ? avoir.categorie.nom_categorie
                          : '-'
                      }}
                    </td>
                    <td class="td-expanded" v-if="checkPermission('FLACCONF')">
                      {{ avoir.confirme === true ? 'OUI' : 'NON' }}
                    </td>
                    <td class="td-expanded" v-if="checkPermission('FLACCP')">
                      {{ avoir.user ? avoir.user : '-' }}
                    </td>
                    <td class="td-expanded" v-if="checkPermission('FLACRP')">
                      -
                    </td>
                    <td
                      class="custom-cell  small-btn-dropdown-libre sticky-header-end"
                      v-if="checkPermission('FLAA')"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <v-menu
                        v-model="avoir.checked"
                        :close-on-content-click="false"
                        :nudge-width="100"
                        :left="true"
                        offset-x
                        class="actions-menu"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="#704ad1" dark v-bind="attrs" v-on="on">
                            <font-awesome-icon icon="bars" />
                          </v-btn>
                        </template>
                        <v-card>
                          <v-list class="actions-list">
                            <!--VISUALISATION FACTURE-->
                            <v-list-item
                              v-if="checkPermission('FLAF')"
                              class="list-dropdown"
                            >
                              <v-list-item-action
                                v-if="checkPermission('FLAF')"
                                title="Afficher document"
                              >
                                <VisualisationFacture
                                  :awesome="false"
                                  :facture="avoir.id"
                                  :permission="checkPermission('FLAF')"
                                  title="Afficher document"
                                ></VisualisationFacture>
                              </v-list-item-action>
                            </v-list-item>
                            <!--MODIFICATION FACTURE-->
                            <v-list-item
                              class="list-dropdown"
                              v-if="
                                (avoir.user_id === idConnecter ||
                                  computeVendeursNames.includes(
                                    avoir.vendeur
                                  )) &&
                                  ((checkPermission('FLMF') &&
                                    avoir.confirme === false &&
                                    avoir.type != 'Facture d\'avoir' &&
                                    (avoir.etat === 'Créé' ||
                                      avoir.etat === 'A payer') &&
                                    avoir &&
                                    avoir.FactureAvoir &&
                                    avoir.FactureAvoir.length === 0) ||
                                    (avoir.type == 'Facture d\'avoir' &&
                                      avoir.motif_avoir == 'ristourne' &&
                                      checkPermission('FLMFFC')))
                              "
                            >
                              <v-list-item-action
                                @click.prevent.stop="
                                  onClickNavigate(data.id, 'Modification')
                                "
                              >
                                <div class="action-title">
                                  Modifier document
                                </div>
                              </v-list-item-action>
                            </v-list-item>
                            <!--TELECHARGER FACTURE-->
                            <v-list-item
                              v-if="checkPermission('FLTFP')"
                              class="list-dropdown"
                            >
                              <v-list-item-action
                                @click.prevent.stop="
                                  FacuresToDownloadOnePDF(avoir)
                                "
                              >
                                <div class="action-title">
                                  Télécharger document
                                </div>
                              </v-list-item-action>
                            </v-list-item>
                            <!--HISTORIQUE FACTURE-->
                            <v-list-item
                              v-if="checkPermission('FLHFAVPD')"
                              class="list-dropdown"
                            >
                              <v-list-item-action>
                                <Historique
                                  :awesome="false"
                                  :permission="checkPermission('FLHFAVPD')"
                                  :index="avoir.id"
                                  :dataToUse="avoir.historique"
                                  @returnHistorique="returnHistorique"
                                  :styleMenuBtn="true"
                                  title="Historique document"
                                />
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="footer-style-table">
      <div
        v-if="
          computedTotal &&
            (computedTotal.ht > 0 ||
              computedTotal.ttc > 0 ||
              computedTotal.someApaye > 0)
        "
        class="style-somme-th-ttc pl-2"
      >
        <div>
          <span class="bold-text"> Total HT:</span>
          {{ computedTotal.ht
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            .replaceAll(',', ' '), }}
          € {{ ' ' }} <span class="bold-text">Total TTC:</span>

          {{
            computedTotal.ttc
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}
          € {{ ' ' }}
          <span class="bold-text"> Total à payer : </span>
          {{
            computedTotal.someApaye
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}
          €
        </div>
      </div>
      <div class="text-center pagination-table">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          color="#704ad1"
          item-color="#704ad1"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetY: true,
            closeOnClick: true,
            closeOnContentClick: true
          }"
        ></v-select>
      </div>
    </div>
    <v-dialog
      v-model="pieceJoint"
      persistent
      max-width="1500px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-show"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"> Pièces jointes </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalPieceJoint('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="files"
                  counter
                  label="Fichiers"
                  multiple
                  placeholder="Aucun fichier selectionné"
                  :persistent-placeholder="true"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip label small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <div v-if="files.length > 0">
              <v-row>
                <p class="file_name">Nouveau(x) Fichier(s)</p>
              </v-row>
              <v-row v-for="(file, index) in files" :key="'file' + index">
                <v-col cols="4">
                  <p>
                    {{ file.name }}
                  </p>
                </v-col>
                <v-col cols="7">
                  <v-textarea
                    outlined
                    label="Description"
                    v-model="file.description"
                    :persistent-placeholder="true"
                    rows="3"
                    required
                    color="#704ad1"
                  ></v-textarea
                ></v-col>
                <v-col cols="1">
                  <v-btn
                    class="mx-2 button-add"
                    small
                    color="red"
                    @click.prevent.stop="deleteFile(index)"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </div>
            <div
              v-if="fichiers && fichiers.files && fichiers.files.length != 0"
            >
              <v-row>
                <p p class="file_name">
                  Fichier(s) Récent(s)
                </p>
              </v-row>
              <v-row
                v-for="(file, index) in fichiers.files"
                :key="'file' + index"
              >
                <v-col cols="4">
                  <a :href="file.link" target="_blank">{{
                    file.name
                  }}</a></v-col
                >
                <v-col cols="7">
                  <v-textarea
                    outlined
                    label="Description"
                    v-model="file.description"
                    :persistent-placeholder="true"
                    rows="3"
                    required
                    color="#704ad1"
                  ></v-textarea
                ></v-col>
                <v-col cols="1">
                  <v-btn
                    class="mx-2 button-add"
                    small
                    color="red"
                    @click.prevent.stop="deleteFilesLibre(file, index)"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </div>
          </form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleSubmitFiles"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Ajouter
          </v-btn>
          <v-btn text @click="handleModalPieceJoint('hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="validateAvoir"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Générer un avoir</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalValidateAvoir('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols>
                <v-menu :close-on-content-click="true" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dateAvoirTotal"
                      label="Date de création"
                      outlined
                      readonly
                      hide-details
                      v-on="on"
                      required
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      :autofocus="false"
                      color="#704ad1"
                      @click="clickDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateAvoirTotal"
                    no-title
                    locale="fr"
                    color="#704ad1"
                    item-color="#704ad1"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols>
                <v-select
                  :items="templateSociete"
                  class="select-menu"
                  label="Template "
                  :persistent-placeholder="true"
                  v-model="avoirTotalTemplate"
                  no-data-text=""
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  :auto-focus="false"
                  color="#704ad1"
                  item-color="#704ad1"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select
              ></v-col>
            </v-row>
          </form>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="CreateGlobalAvoir"
            :loading="loadingAvoir"
            :disabled="loadingAvoir"
            :class="{ loader: loadingAvoir }"
          >
            Générer
          </v-btn>
          <v-btn text @click="handleModalValidateAvoir('hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="transformFacture"
      max-width="1320px"
      :scrollable="false"
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-rapport"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"> Rapport Création Facture</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="handleModalTransformFacture('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text">
          <v-data-table
            v-model="selectedTable"
            :headers="computedFieldsAvoir"
            :items="getListTransformFacture"
            class="table-facture"
            hide-default-footer
            :calculate-widths="false"
            :fixed-header="true"
            :disable-sort="true"
            hide-action
            :no-data-text="
              loaderData
                ? 'Chargement en cours ...'
                : `Il n'y a aucune donnée à afficher dans ce tableau.`
            "
          >
            <template v-slot:[`item.vendeur`]="{ item }">
              <td class="custom-cell">{{ item.vendeur }}</td>
            </template>
            <!--SOCIETE-->
            <template v-slot:[`item.client`]="{ item }">
              <td class="custom-cell">{{ item.client }}</td>
            </template>
            <!--TYPE-->
            <template v-slot:[`item.num`]="{ item }">
              <td class="custom-cell">{{ item.num }}</td>
            </template>
            <!--NUM-->
            <template v-slot:[`item.montant_ttc`]="{ item }">
              <td class="custom-cell">{{ item.montant_ttc }}</td>
            </template>
            <!--NOMBRE DES PRODUITS-->
            <template v-slot:[`item.number_of_products`]="{ item }">
              <td class="custom-cell">{{ item.number_of_products }} €</td>
            </template>
            <!--OBJET-->
            <template v-slot:[`item.objet`]="{ item }">
              <td class="custom-cell">{{ item.objet }}</td>
            </template>
            <!--TEMPLATE-->
            <template v-slot:[`item.template`]="{ item }">
              <td class="custom-cell">
                {{ item.template }}
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click.prevent="handleModalTransformFacture('hide')"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'Facture-libre',
  data() {
    return {
      avoirTotalTemplate: null,
      validateAvoir: false,
      pieceJoint: false,
      singleExpand: false,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      transformFacture: false,
      menu: false,
      expanded: [],
      selectedTable: [],
      loadingAvoir: false,
      dateAvoirTotal: null,
      showPopover: false,
      idToDownload: { id: null },
      indexEnd: 0,
      roleConnecter: null,
      isAvoir: false,
      vueToDisplay: false,
      famille: 'libre',
      fichiers: null,
      files: [],
      loading: false,
      pdfSource: null,
      pdfToDisplayInIndex: null,
      selected: [],
      filialConnected: null,
      error: null,
      isLoading: false,
      num: null,
      type: [],
      etat: [],
      vendeur: [],
      indexBegin: null,
      categorie: [],
      acheteur: [],
      displayFactureLoading: false,
      mode_reglement: [],
      date_debut: moment(Date.now())
        .subtract(2, 'months')
        .format('YYYY-MM-DD'),
      date_fin: null,
      isOpen: false,
      facturToAvoir: null,
      vueOptions: [
        { text: 'Par défaut', value: false },
        { text: 'Client', value: true }
      ],
      idConnecter: null,
      connectedRole: null,
      column: 'id',
      order: 'DESC',
      checkAll: false,
      dataToUse: [],
      factureAvoirChecked: [],
      etatList: [
        { value: 'Payé', text: 'Payé' },
        { value: 'Payé en partie', text: 'Payé en partie' },
        { value: 'A payer ', text: 'A payer ' }
      ],
      etatListFilter: [
        { value: 'Créé', text: 'Créé' },
        { value: 'Payé', text: 'Payé' },
        { value: 'A payer ', text: 'A payer ' },
        { value: 'Payé en partie', text: 'Payé en partie' },
        { value: 'Annulée', text: 'Annulée' }
      ],
      indexSelectIsOpen: null,
      indexSelectIsOpenClient: null,
      perPage: 100,
      page: 1,
      nums: null,
      perPageList: [
        { value: 100, text: 100 },
        { value: 200, text: 200 },
        { value: 300, text: 300 }
      ],
      typeFacture: [
        { value: 'Facture', text: 'Facture' },
        { value: 'Facture Proforma', text: 'Facture proforma' },
        { value: 'Devis', text: 'Devis' },
        { value: "Facture d'avoir", text: "Facture d'avoir" }
      ],
      loaderData: false,
      isLocalStorageVendeur: [],
      isLocalStorageEtat: [],
      isLocalStorageAcheteur: [],
      isLocalStorageType: [],
      isLocalStorageModeReglement: [],
      isLocalStorageCategories: []
    };
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'getFacturesLibresTh',
      'getFactureLibreLoadingTh',
      'getFactureLibreErroresTh',
      'getFactureLibreTotalRowsTh',
      'getSettingFilialesTh',
      'getFiliaeOfResponsable',
      'getAllCategories',
      'getLoadingMailFactureLibre',
      'getErrorMailingFactureLibre',
      'getSuccessSendMailsFactureLibre',
      'getOtherErrorMailFactureLibre',
      'getShowNotifExportFile',
      'TypeReglement',
      'formateDateGetters',
      'getUserData',
      'getListTransformFacture',
      'computedRouterLinkFacture',
      'templateSociete',
      'countNonEmptyArrays'
    ]),
    computedLengthFilter() {
      return this.countNonEmptyArrays(
        this.acheteur,
        this.etat,
        this.type,
        this.categorie,
        this.mode_reglement,
        this.vendeur
      );
    },
    returnTableFiltre() {
      if (
        (this.vendeur && this.vendeur.length) ||
        (this.acheteur && this.acheteur.length) ||
        (this.etat && this.etat.length) ||
        (this.type && this.type.length) ||
        (this.categorie && this.categorie.length) ||
        (this.mode_reglement && this.mode_reglement.length)
      ) {
        return true;
      }
      return false;
    },
    returnTableChips() {
      if (
        this.date_debut !== null ||
        this.date_fin !== null ||
        this.num !== null ||
        this.vueToDisplay !== null
      ) {
        return true;
      }
      return false;
    },
    computedFieldsAvoir() {
      return [
        {
          value: 'vendeur',
          text: 'SOCIETE'
        },
        {
          value: 'client',
          text: 'CLIENT'
        },
        {
          value: 'num',
          text: 'NUMERO FACTURE'
        },
        {
          value: 'montant_ttc',
          text: 'MONTANT'
        },
        {
          value: 'number_of_products',
          text: 'NOMBRE DES PRODUITS'
        },
        {
          value: 'objet',
          text: 'OBJET'
        },
        {
          value: 'template',
          text: 'TEMPLATE'
        }
      ];
    },
    totalPages() {
      if (this.getFactureLibreTotalRowsTh) {
        return Math.ceil(this.getFactureLibreTotalRowsTh / this.perPage);
      }
      return this.getFactureLibreTotalRowsTh;
    },
    computedFilter() {
      return {
        num: this.num,
        type: this.type,
        etat: this.etat,
        vendeur: this.vendeur,
        categorie: this.categorie,
        acheteur: this.acheteur,
        mode_reglement: this.mode_reglement,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        col: this.column
      };
    },

    computedTotal() {
      if (this.vueToDisplay === false) {
        let someHt = 0;
        let someHTAvoir = 0;
        let someTtc = 0;
        let somTtcAvoir = 0;
        let someApaye = 0;
        for (let i = 0; i < this.computedGetDataToUseTotal.length; i++) {
          // if (this.computedGetDataToUse[i].FactureAvoir.length === 0) {
          someHt = someHt + this.computedGetDataToUseTotal[i].total_ht;
          someTtc = someTtc + this.computedGetDataToUseTotal[i].ttc;
          someApaye = someApaye + this.computedGetDataToUseTotal[i].reste;
          if (this.computedGetDataToUseTotal[i].FactureAvoir.length !== 0) {
            for (
              let j = 0;
              j < this.computedGetDataToUseTotal[i].FactureAvoir.length;
              j++
            ) {
              someHTAvoir =
                someHTAvoir +
                this.computedGetDataToUseTotal[i].FactureAvoir[j].total_ht;
              somTtcAvoir =
                somTtcAvoir +
                parseFloat(
                  this.computedGetDataToUseTotal[i]?.FactureAvoir[
                    j
                  ].total_ttc.replace(' ', '')
                );
            }
          }
          // }
        }
        return {
          ht:
            Math.round(
              (Math.round(someHt * 100) / 100 -
                Math.round(someHTAvoir * 100) / 100) *
                100
            ) / 100,
          ttc:
            Math.round(
              (Math.round(someTtc * 100) / 100 -
                Math.round(somTtcAvoir * 100) / 100) *
                100
            ) / 100,
          someApaye: Math.round(someApaye * 100) / 100
        };
      } else {
        let someHt = 0;
        let someTtc = 0;
        let someApaye = 0;
        let someHTAvoir = 0;
        let somTtcAvoir = 0;
        for (let i = 0; i < this.computedCheckedRows?.length; i++) {
          someHt = someHt + this.computedCheckedRows[i].total_ht;
          someTtc = someTtc + this.computedCheckedRows[i].ttc;
          someApaye = someApaye + this.computedCheckedRows[i].reste;
          if (this.computedCheckedRows[i]?.FactureAvoir?.length !== 0) {
            for (
              let j = 0;
              j < this.computedCheckedRows[i]?.FactureAvoir?.length;
              j++
            ) {
              someHTAvoir =
                someHTAvoir +
                this.computedCheckedRows[i].FactureAvoir[j].total_ht;

              somTtcAvoir =
                somTtcAvoir +
                parseFloat(
                  this.computedCheckedRows[i]?.FactureAvoir[
                    j
                  ]?.total_ttc.replace(' ', '')
                );
            }
          }
          // }
        }
        return {
          ht:
            Math.round(
              (Math.round(someHt * 100) / 100 -
                Math.round(someHTAvoir * 100) / 100) *
                100
            ) / 100,
          ttc:
            Math.round(
              (Math.round(someTtc * 100) / 100 -
                Math.round(somTtcAvoir * 100) / 100) *
                100
            ) / 100,
          someApaye: Math.round(someApaye * 100) / 100
        };
      }
    },
    computedCheckChipFilter() {
      if (
        this.vendeur.length > 0 ||
        this.acheteur.length > 0 ||
        this.etat.length > 0 ||
        this.type.length > 0 ||
        this.mode_reglement.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedCheckedRowsOneClient() {
      let tableReglement = [];
      let tableReglementCheck = [];
      let filiale_list = this.getUserData.filiales.map(item => item.id);

      if (this.vueToDisplay == true) {
        if (this.getFacturesLibresTh.length) {
          this.getFacturesLibresTh.forEach(element => {
            if (element.factures) {
              element.factures.forEach(facture => {
                if (facture.check == true) {
                  tableReglementCheck.push(facture);
                }
                if (
                  facture.check == true &&
                  facture.reste != '0' &&
                  facture.type != 'Facture proforma' &&
                  facture.type != 'Devis' &&
                  (facture.etat == 'A payer' ||
                    facture.etat == 'Payé en partie' ||
                    facture.etat == 'Annulée') &&
                  facture.belongsTo == true
                ) {
                  if (tableReglement.length > 0) {
                    if (filiale_list.includes(tableReglement[0].client_id)) {
                      if (
                        facture.vendeur_id == tableReglement[0].vendeur_id &&
                        filiale_list.includes(facture.client_id) &&
                        facture.client_id == tableReglement[0].client_id
                      ) {
                        tableReglement.push(facture);
                      }
                    } else {
                      if (
                        facture.client_id == tableReglement[0].client_id &&
                        facture.vendeur_id == tableReglement[0].vendeur_id
                      ) {
                        tableReglement.push(facture);
                      }
                    }
                  } else {
                    tableReglement.push(facture);
                  }
                }
              });
            }
          });
        }
      } else {
        if (this.getFacturesLibresTh.length) {
          this.getFacturesLibresTh.forEach(facture => {
            if (facture.check == true) {
              tableReglementCheck.push(facture);
            }
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type != 'Facture proforma' &&
              facture.type != 'Devis' &&
              (facture.etat == 'A payer' ||
                facture.etat == 'Payé en partie' ||
                facture.etat == 'Annulée') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        }
      }
      return tableReglement.length == tableReglementCheck.length &&
        tableReglement.length > 0
        ? true
        : false;
    },
    computedCheckedRowsOneClientFacture() {
      let tableReglement = [];
      let filiale_list = this.getUserData.filiales.map(item => item.id);

      if (this.vueToDisplay == true) {
        if (this.getFacturesLibresTh.length) {
          this.getFacturesLibresTh.forEach(element => {
            if (element.factures) {
              element.factures.forEach(facture => {
                if (
                  facture.check == true &&
                  facture.reste != '0' &&
                  facture.type != 'Facture proforma' &&
                  facture.type != 'Devis' &&
                  (facture.etat == 'A payer' ||
                    facture.etat == 'Payé en partie') &&
                  facture.belongsTo == true
                ) {
                  if (tableReglement.length > 0) {
                    if (filiale_list.includes(tableReglement[0].client_id)) {
                      if (
                        facture.vendeur_id == tableReglement[0].vendeur_id &&
                        filiale_list.includes(facture.client_id) &&
                        facture.client_id == tableReglement[0].client_id
                      ) {
                        tableReglement.push(facture);
                      }
                    } else {
                      if (
                        facture.client_id == tableReglement[0].client_id &&
                        facture.vendeur_id == tableReglement[0].vendeur_id
                      ) {
                        tableReglement.push(facture);
                      }
                    }
                  } else {
                    tableReglement.push(facture);
                  }
                }
              });
            }
          });
        }
      } else {
        if (this.getFacturesLibresTh.length) {
          this.getFacturesLibresTh.forEach(facture => {
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type != 'Facture proforma' &&
              facture.type != 'Devis' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        }
      }

      return tableReglement;
    },
    computedTypeReglement() {
      return this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedCheckedRows() {
      let result1 = [];
      if (this.vueToDisplay === true) {
        this.getFacturesLibresTh?.map(row => {
          row?.factures?.map(fac => {
            if (fac.check == true) {
              result1.push(fac);
            }
          });
        });
      }
      return result1;
    },
    computedFields() {
      let fields = [
        {
          value: 'check_all',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-1'
        },
        {
          value: 'data-table-expand',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-2'
        },
        {
          value: 'pices-icon',
          text: '',
          views: true,
          for: 'all',
          show: true,
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-3'
        },
        {
          value: 'Acheteur',
          text: 'CLIENT',
          sortable: true,
          show: this.checkPermission('FLACC'),
          class: 'societe-th'
        },
        {
          value: 'Vendeur',
          text: 'SOCIETE',
          sortable: true,
          show: this.checkPermission('FLACS'),
          class: 'societe-th'
        },
        {
          value: 'type',
          text: 'TYPE DOCUMENT',
          sortable: true,
          show: this.checkPermission('FLACTD')
        },

        {
          value: 'num',
          text: 'NUMERO',
          sortable: true,
          show: this.checkPermission('FLACN')
        },
        {
          value: 'date_creation',
          text: 'DATE DE CRÉATION',
          sortable: true,
          show: this.checkPermission('FLACDC')
        },
        {
          value: 'reste',
          text: 'Reste à payer',
          sortable: true,
          show: this.checkPermission('FLACRAP')
        },
        {
          value: 'etat',
          text: 'ETAT',
          sortable: true,
          show: this.checkPermission('FLACE')
        },
        {
          value: 'total_net_ht',
          text: 'NET HT',
          sortable: true,
          show: this.checkPermission('FLACNHT')
        },
        {
          value: 'total_tva',
          text: 'TVA',
          sortable: true,
          show: this.checkPermission('FLACTVA')
        },
        {
          value: 'total_ttc',
          text: 'TOTAL TTC',
          sortable: true,
          show: this.checkPermission('FLACTTTC')
        },
        {
          value: 'date_paiement',
          text: 'DATE DE PAIEMENT',
          sortable: true,
          show: this.checkPermission('FLADP')
        },
        {
          value: 'reglement',
          text: 'REGLEMENT',
          sortable: false,
          show: this.checkPermission('FLARG')
        },
        {
          value: 'statut_client',
          text: 'STATUE CLIENT',
          sortable: true,
          show: this.checkPermission('FLACSC')
        },
        {
          value: 'categorie',
          text: 'CATEGORIE',
          sortable: true,
          show: this.checkPermission('FLACCAT')
        },

        {
          value: 'confirme',
          text: 'COMPTABILISE',
          sortable: true,
          show: this.checkPermission('FLACCONF')
        },
        {
          value: 'User',
          text: 'CREE PAR',
          sortable: true,
          show: this.checkPermission('FLACCP')
        },
        {
          value: 'num_proforma',
          text: 'REFERENCE PROFORMA',
          sortable: true,
          show: this.checkPermission('FLACRP')
        },

        {
          value: 'action',
          text: '',
          sortable: false,
          show: this.checkPermission('FLAA'),
          width: '40px !important',
          class: 'sticky-header-end'
        }
      ];
      return fields.filter(item => item.show === true);
    },
    computedGetDataToUse() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check === true && row.type !== "Facture d'avoir"
      );
      return checkesRows;
    },
    computedGetDataToUseTotal() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check === true
      );
      return checkesRows;
    },

    computedCheckRows() {
      let selected = [];
      this?.getFacturesLibresTh?.forEach(element => {
        if (element.check == true) {
          selected.push(element.id);
        }
      });
      return selected;
    },
    computedCheckRowsexportXls() {
      let result1 = [];

      if (this.vueToDisplay === true) {
        this.getFacturesLibresTh?.map(row => {
          row?.factures?.map(fac => {
            if (fac.check == true) {
              result1.push(fac.id);
            }
          });
        });
      }
      return result1;
    },
    computeVendeursNames() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return item.name;
      });
      return vendeurNames;
    },
    computedGetAcheteurs() {
      let acheteurNames = [];
      this.getSettingFilialesTh.map(item => {
        if (item.name !== null) {
          acheteurNames.push({ value: item.id, text: item.name });
        }
      });
      let jsonObject = acheteurNames.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      uniqueArray.sort((a, b) => a.text.localeCompare(b.text));
      return uniqueArray;
    },
    computeGetcategories() {
      let arrayCategorie = this.getAllCategories.map(item => {
        return { value: item.id, text: item.nom_categorie };
      });
      let jsonObject = arrayCategorie.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      uniqueArray.sort((a, b) => a.text.localeCompare(b.text));
      return uniqueArray;
    },
    computedCheck() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      if (checkesRows.length > 0) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions([
      'exportFactureLibre',
      'downloadPDF',
      'displayFact',
      'fetchAllFacturesLibres',
      'getSettingFilialeTh',
      'updatePaymentDate',
      'downloadZIP',
      'downloadOnePDF',
      'fetchAllCategories',
      'confirmedBills',
      'CreateBillAvoirGlobal',
      'exportExcelFactureLibre',
      'getFilialsOfConnectedResponsable',
      'getCategoriesFactureLibre',
      'transformProformatToFacture',
      'sendMailFactureLibre',
      'getAllProductsForfiltre',
      'updateClientStatuPayment',
      'initFactureLibreState',
      'getAllTypesReglement',
      'uploadFileLibre',
      'deleteAttachementLibre',
      'updateFileLibre',
      'fetchDataFacturesDetails',
      'fecthFactureNumbersInExcelFile',
      'getAllTemplateSociete'
    ]),
    clickDate() {
      if (
        document.getElementsByClassName(
          'v-menu__content theme--light v-menu__content--fixed menuable__content__active'
        ) &&
        document.getElementsByClassName(
          'v-menu__content theme--light v-menu__content--fixed menuable__content__active'
        ).length
      ) {
        document.getElementsByClassName(
          'v-menu__content theme--light v-menu__content--fixed menuable__content__active'
        )[0].style.minWidth = '274px';
      }
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.handleFilter();
    },
    async getDataForOneRow(item) {
      item.showingDetails = !item.showingDetails;
      if (item.showingDetails == true) {
        await this.fetchDataFacturesDetails({
          per_page: this.perPage,
          page: this.page,
          num: this.num,
          item: item,
          type: this.type.filter(item => item?.value != null),
          etat: this.etat.filter(item => item?.value != null),
          mode_reglement: this.mode_reglement?.filter(
            item => item?.value != null
          ),
          produit: this.produit?.filter(item => item?.value != null),
          vendeur: this.vendeur?.filter(item => item?.value != null),
          categorie_id: this.categorie.filter(item => item?.value != null),
          acheteur_id: this.acheteur?.filter(item => item?.value != null),
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          column: this.column,
          order: this.order,
          objet: this.objet,
          famille: 'libre'
        });
        if (!this.expanded.includes(item)) {
          this.expanded.push(item);
        }
      }
    },
    showRowDetailsVueDefault(item) {
      item.showDetails = !item.showDetails;
      if (item.showDetails == true) {
        if (!this.expanded.includes(item)) {
          this.expanded.push(item);
        }
      }
    },
    onClickNavigate(id, type) {
      if (type === 'Modification') {
        this.$router.push(`/update-facture/${id}`);
      } else {
        this.$router.push(`/duplicate-facture/${id}`);
      }
    },
    openPopover() {
      this.menu = true;
    },
    validerPopover() {
      //
      //SET FILTER FROM POPOVER
      //
      this.vendeur = this.isLocalStorageVendeur;
      this.acheteur = this.isLocalStorageAcheteur;
      this.etat = this.isLocalStorageEtat;
      this.type = this.isLocalStorageType;
      this.mode_reglement = this.isLocalStorageModeReglement;
      this.categorie = this.isLocalStorageCategories;
      //
      //END SET FILTER FROM POPOVER
      //
      this.handleFilter();
      this.menu = false;
    },
    closePopover() {
      this.menu = false;
      this.vendeur = [];
      this.acheteur = [];
      this.etat = [];
      this.type = [];
      this.categorie = [];
      this.mode_reglement = [];
      //POPOVER FILTER
      this.isLocalStorageVendeur = [];
      this.isLocalStorageAcheteur = [];
      this.isLocalStorageEtat = [];
      this.isLocalStorageType = [];
      this.isLocalStorageCategories = [];
      this.isLocalStorageModeReglement = [];
      this.handleFilter();
    },
    searchWithMultipleFacture(value) {
      if (this.nums != value) {
        this.nums = value;
        this.handleFilter();
      }
    },
    returnHistorique(data) {
      this.showPopover = data;
    },
    remove(item, filter, fct, origin) {
      this[filter].splice(this[filter].indexOf(item), 1);
      if (origin) {
        this[origin] = this[filter];
      }
      this[fct]();
    },
    async handleSubmitFiles() {
      this.error = [];
      if (this.files.length != 0 || this.fichiers.files.length != 0) {
        this.loading = true;
        if (this.fichiers?.files?.length != 0) {
          let bodyFormData = new FormData();
          for (let i = 0; i < this.fichiers.files?.length; i++) {
            bodyFormData.append(
              'files[' + i + '][file_id]',
              this.fichiers.files[i].id
            );
            bodyFormData.append(
              'files[' + i + '][description]',
              this.fichiers.files[i].description
            );
          }
          const responseUpdate = await this.updateFileLibre({
            bodyFormData: bodyFormData,
            facture: this.fichiers
          });
          if (responseUpdate.success) {
            if (this.files.length != 0) {
              let bodyFormData = new FormData();
              bodyFormData.append('facture_id', this.fichiers.id);
              for (let i = 0; i < this.files?.length; i++) {
                bodyFormData.append('files[' + i + '][file]', this.files[i]);
                if (this.files[i]['description'] != undefined) {
                  bodyFormData.append(
                    'files_descriptions[' + i + ']',
                    this.files[i]['description']
                  );
                }
              }
              const responseUpload = await this.uploadFileLibre({
                bodyFormData: bodyFormData,
                facture_id: this.fichiers.id,
                vue: this.vueToDisplay
              });
              if (responseUpload.success) {
                this.loading = false;
                this.files = [];
              }
            } else {
              this.loading = false;
            }
          }
        } else {
          if (this.files.length != 0) {
            let bodyFormData = new FormData();
            bodyFormData.append('facture_id', this.fichiers.id);
            for (let i = 0; i < this.files?.length; i++) {
              bodyFormData.append('files[' + i + '][file]', this.files[i]);
              if (this.files[i]['description'] != undefined) {
                bodyFormData.append(
                  'files_descriptions[' + i + ']',
                  this.files[i]['description']
                );
              }
            }
            const responseUploadFile = await this.uploadFileLibre({
              bodyFormData: bodyFormData,
              facture_id: this.fichiers.id,
              vue: false
            });
            if (responseUploadFile.success) {
              this.loading = false;
            }
          }
        }
      } else {
        this.error = 'Importer au moins un fichier';
      }
    },
    resetModal() {
      this.fichiers = {};
      this.files = [];
      this.dateAvoirTotal = null;
    },
    deleteFile(index) {
      this.files.splice(index, 1);
    },
    handleModalPieceJoint(action) {
      if (action == 'show') {
        this.pieceJoint = true;
      } else {
        this.pieceJoint = false;
        this.resetModal();
      }
    },
    handleModalTransformFacture(action) {
      if (action == 'show') {
        this.transformFacture = true;
      } else {
        this.transformFacture = false;
        this.handleFilter();
      }
    },
    async handleModalValidateAvoir(action) {
      if (action == 'show') {
        this.avoirTotalTemplate = null;
        this.validateAvoir = true;
        const response = await this.getAllTemplateSociete('libre');
        if (response === true) {
          const result = this.templateSociete?.filter(
            item => item.hasOwnProperty('favori') && item.favori === true
          );
          this.avoirTotalTemplate = result.length ? result[0].id : null;
        }
      } else {
        this.validateAvoir = false;
        this.resetModal();
        this.handleFilter();
      }
    },
    handleFichiers(item, action) {
      this.fichiers = item;
      this.handleModalPieceJoint(action);
    },
    async deleteFilesLibre(item, index) {
      const response = await this.deleteAttachementLibre(item.id);
      if (response) {
        this.fichiers.files.splice(index, 1);
      }
    },
    checkAllVerifFactureAvoir(data, item, itemD, index) {
      if (itemD.checkAvoir == false && data.checkClient == true) {
        data.checkClient = false;
      }
      if (itemD.checkAvoir == false && this.checkAll == true) {
        this.checkAll = false;
      }
      if (itemD.checkAvoir == false && item.checkAll == true) {
        item.checkAll = false;
      }
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.checkClient == true) {
        data.check = false;
      }
      if (data.checkClient == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.FactureAvoir.map(itemP => {
        itemP.checkAvoir = item.check;
      });
    },
    async visualisationStep(method, type, item, id) {
      var i = this.indexBegin;
      if (method == 'next') {
        i = ++this.indexBegin;
      } else {
        i = --this.indexBegin;
      }
      if (type == 'default') {
        this.$refs.VisualisationFacture.visualisation(
          this.getFacturesLibresTh[i].id
        );
      } else {
        let indexModal = this.$refs.VisualisationFacture.findIndex(
          i => i.facture == id
        );
        if (indexModal >= 0) {
          this.$refs.VisualisationFacture[indexModal].visualisation(
            item.factures[i].id
          );
        }
      }
    },
    openConfirmAvoirModal(data) {
      this.facturToAvoir = data;
      this.handleModalValidateAvoir('show');
    },
    disabledStartDateFilter(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    indexEndDateFiltre(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    onChangeVue() {
      this.initFactureLibreState();
      this.refrech();
    },
    async refrech() {
      this.isLoading = true;
      this.column = 'id';
      this.ordre = 'DESC';
      this.handleFilter();
      this.isLoading = false;
    },
    setLocalStorageFactureLibre() {
      localStorage.setItem(
        'facture-libre',
        JSON.stringify({
          groupedBySociete: this.vueToDisplay,
          num: this.num,
          type: this.type.filter(item => item?.value != null),
          etat: this.etat.filter(item => item?.value != null),
          mode_reglement: this.mode_reglement?.filter(
            item => item?.value != null
          ),
          vendeur: this.vendeur?.filter(item => item?.value != null),
          categorie_id: this.categorie.filter(item => item?.value != null),
          acheteur_id: this.acheteur?.filter(item => item?.value != null),
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          column: this.column,
          order: this.order,
          famille: this.famille,
          nums: this.nums
        })
      );
    },
    async handleFilter() {
      if (this.isLoading == false) {
        this.isLoading = true;
      }
      this.setLocalStorageFactureLibre();
      this.$nextTick(async () => {
        await this.fetchAllFacturesLibres({
          groupedBySociete: this.vueToDisplay,
          per_page: this.perPage,
          page: this.page,
          num: this.num,
          type: this.type.filter(item => item?.value != null),
          etat: this.etat.filter(item => item?.value != null),
          mode_reglement: this.mode_reglement?.filter(
            item => item?.value != null
          ),
          vendeur: this.vendeur?.filter(item => item?.value != null),
          categorie_id: this.categorie.filter(item => item?.value != null),
          acheteur_id: this.acheteur?.filter(item => item?.value != null),
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          column: this.column,
          order: this.order,
          famille: this.famille,
          nums: this.nums
        });
        this.isLoading = false;
      });
    },
    changenum(event) {
      this.num = event;
      this.handleFilter();
    },
    async listOrder(col, ord) {
      this.isLoading = true;
      this.column = col;
      this.order = ord;
      this.handleFilter();
      this.isLoading = false;
    },
    async changePerPage() {
      this.isLoading = true;
      this.page = 1;
      this.handleFilter();
      this.isLoading = false;
    },
    async visualisation(index, item, type, lengthF) {
      if (item.type === "Facture d'avoir") {
        this.isAvoir = true;
      } else {
        this.isAvoir = false;
      }
      this.indexBegin = index;
      if (type == 'default') {
        this.indexEnd = this.getFacturesLibresTh.length - 1;
      } else {
        this.indexEnd = lengthF - 1;
      }
    },
    async pagination(paginate) {
      this.isLoading = true;
      this.page = paginate;
      this.handleFilter();
      this.isLoading = false;
    },
    checkAllFunction() {
      this?.getFacturesLibresTh?.map(item => (item.check = this.checkAll));
    },
    checkAllFunctionClienVue() {
      this?.getFacturesLibresTh?.map(item => {
        item.checkClient = this.checkAll;
        item.factures.map(itemD => {
          itemD.check = this.checkAll;
          itemD.FactureAvoir.map(itemP => {
            itemP.checkAvoir = this.checkAll;
          });
        });
      });
    },
    checkAllVerif(item) {
      if (item.checkClient == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.factures.map(itemD => {
        itemD.check = item.checkClient;
        itemD.FactureAvoir.map(itemP => {
          itemP.checkAvoir = item.check;
        });
      });
    },
    checkOneFunction(data) {
      data.check == !data.check;
    },
    checkOneAvoirFunction(data) {
      if (data.checkAvoir === true) {
        this.factureAvoirChecked.push(data.id);
      } else {
        let index = this.factureAvoirChecked.findIndex(
          element => element.id === data.id
        );
        this.factureAvoirChecked.splice(index, 1);
      }
    },
    async CreateGlobalAvoir() {
      this.loadingAvoir = true;
      let avoir = { ...this.facturToAvoir };
      avoir.date = this.dateAvoirTotal;
      avoir.template_id = this.avoirTotalTemplate;
      const response = await this.CreateBillAvoirGlobal(avoir);
      if (response.success) {
        this.loadingAvoir = false;
        this.handleModalValidateAvoir('hide');
        this.flashMessage.show({
          status: 'success',
          title: '',
          clickable: true,
          time: 0,
          message:
            'Avoir sur la Facture N° ' +
            this.facturToAvoir.num +
            ' a été ajouté avec succès'
        });
        this.handleFilter();
      } else {
        this.loadingAvoir = false;
        this.handleModalValidateAvoir('hide');
        this.flashMessage.show({
          status: 'error',
          title: '',
          clickable: true,
          time: 0,
          message: response.response
        });
      }
      this.dateAvoirTotal = null;
    },
    async updateEtatPaimentClient(data, result) {
      data.statut_client = result.value;
      data.openSelectClient = false;
      await this.updateClientStatuPayment(data);
    },
    FacuresToDownloadOnePDFEvent(type, item) {
      if (type == 'default') {
        this.FacuresToDownloadOnePDF(this.getFacturesLibresTh[this.indexBegin]);
      } else {
        this.FacuresToDownloadOnePDF(item.factures[this.indexBegin].id);
      }
    },
    async FacuresToDownloadOnePDF(idPdf) {
      idPdf.downloadPDF = true;
      let ids = [];
      ids.push({
        id: idPdf.id
      });
      let bodyFormData = new FormData();
      bodyFormData.append('ids[0]', ids[0]['id']);
      await this.downloadPDF({ id: ids[0].id });
      idPdf.downloadPDF = false;
    },
    async FacuresToConfirme() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      let ids = [];
      let bodyFormData = new FormData();
      for (let i = 0; i < checkesRows.length; i++) {
        ids.push(checkesRows[i].id);
      }
      for (let i = 0; i < this.factureAvoirChecked.length; i++) {
        ids.push(this.factureAvoirChecked[i]);
      }
      for (let i = 0; i < ids.length; i++) {
        bodyFormData.append('ids[' + i + ']', ids[i]);
      }
      const response = await this.confirmedBills(bodyFormData);
      if (response === true) {
        this.isLoading = true;
        this.handleFilter();
        this.isLoading = false;
      }
    },
    async trasformFacture(item) {
      this.loaderData = true;
      this.handleModalTransformFacture('show');
      let bodyFormData = new FormData();
      bodyFormData.append('ids[' + 0 + ']', item.id);
      const response = await this.transformProformatToFacture(bodyFormData);
      this.loaderData = response ?? false;
    }
  },
  components: {
    IconFiltre: () => import('@/assets/img/IconFiltre.vue'),
    ExportDynamic: () =>
      import('../ThProject/frais/fraisDynamic/component/exportDynamic.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    Historique: () => import('@/views/component/Historique.vue'),
    InputFileExcelNumDossier: () =>
      import('../ThProject/componentRapportTh/inputFileExcelNumDossier.vue'),
    VisualisationFacture: () =>
      import('@/views/component/Facture/VisualisationFacture.vue'),
    reglement: () => import('@/views/component/reglement/reglement.vue'),
    PopoverReglementFacture: () =>
      import('@/views/component/Facture/PopoverReglementFacture.vue'),
    sendMail: () => import('@/views/component/mail/sendMail.vue')
  },
  async mounted() {
    this.roleConnecter = JSON.parse(localStorage.getItem('User-Details'))?.role;
    this.idConnecter = JSON.parse(localStorage.getItem('User-Details'))?.id;
    this.filialConnected = JSON.parse(
      localStorage.getItem('User-Details')
    ).filiale_name;
    this.connectedRole = JSON.parse(localStorage.getItem('User-Details'))?.role;
    if (localStorage.getItem('facture-libre')) {
      //
      //SET FILTER FROM LOCAL STORGE
      //
      let local = JSON.parse(localStorage.getItem('facture-libre'));
      this.date_debut = local.date_debut ? local.date_debut : null;
      this.date_fin = local.date_fin ? local.date_fin : null;
      this.vendeur = local.vendeur ? local.vendeur : [];
      this.isLocalStorageVendeur = local.vendeur;
      this.acheteur = local.acheteur_id ? local.acheteur_id : [];
      this.isLocalStorageAcheteur = local.acheteur_id;
      this.etat = local.etat ? local.etat : [];
      this.isLocalStorageEtat = local.etat;
      this.type = local.type ? local.type : [];
      this.isLocalStorageType = local.type;
      this.mode_reglement = local.mode_reglement ? local.mode_reglement : [];
      this.isLocalStorageModeReglement = local.mode_reglement;
      this.categorie = local.categorie_id ? local.categorie_id : [];
      this.isLocalStorageCategories = local.categorie_id;
      this.nums = local.nums ? local.nums : null;
      //
      //END SET FILTER FROM LOCAL STORGE
      //
    } else {
      this.setLocalStorageFactureLibre();
    }
    this.isLoading = true;
    await this.getSettingFilialeTh();
    await this.getFilialsOfConnectedResponsable();
    await this.getCategoriesFactureLibre();
    await this.handleFilter();
    this.isLoading = false;
    await this.getAllTypesReglement();
    await this.getCategoriesFactureLibre();
  }
};
</script>
<style lang="scss">
.small-btn-dropdown-libre {
  .v-btn:not(.v-btn--round).v-size--default {
    height: 30px !important;
    min-width: 30px !important;
    padding: 0px 11px !important;
  }
}
</style>
<style lang="scss" scoped>
.content-cadre-table {
  height: calc(100vh - 221px) !important;
}
.list-dropdown {
  &:hover {
    background-color: #f1edfb;
    cursor: pointer;
  }
}

.file_name {
  padding-top: 11px;
  color: #525252;
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
}

.space-table-filter-fac-libre {
  padding-bottom: 1px !important;
}

.table-fac-libre {
  &.padding-table {
    padding-top: 19px;
  }
}
</style>
